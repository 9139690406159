import React from 'react';
import {
  arrayOf,
  bool,
  node,
  oneOfType,
  string
} from 'prop-types';
import classNames from 'classnames';
import { Image } from '@thd-olt-component-react/core-ui';

export const SampleImage = ({
  children, mini, url
}) => {
  const imageContentClasses = classNames('sui-relative sui-h-full sui-w-full',
    'sui-flex sui-justify-center sui-items-center', {
      'sui-m-0 sui-inline-block': mini
    });
  return (
    <div className={imageContentClasses}>
      <Image
        className="sui-rounded-sm"
        alt="sample"
        src={url}
        height="300px"
        width="300px"
      />
      {children}
    </div>
  );
};

SampleImage.displayName = 'Stencil Sample Image';

SampleImage.propTypes = {
  children: oneOfType([node, arrayOf(node)]),
  mini: bool,
  url: string.isRequired
};

SampleImage.defaultProps = {
  children: null,
  mini: false
};
