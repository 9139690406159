import React from 'react';
import { func, string } from 'prop-types';
import { Close } from '@one-thd/sui-icons';
import { SampleImage } from './SampleImage';

export const TraySample = ({ imageUrl, onClick }) => {

  return (
    <div
      className={
        'sui-flex sui-h-12 sui-w-12 sui-border-solid sui-border-primary sui-border-1 '
        + 'sui-shadow-md sui-rounded-md sui-p-1px sui-relative sui-mr-3'
      }
    >
      <button
        type="button"
        onClick={onClick}
        data-testid="tray-sample-button"
        aria-label="Close"
      >
        <div
          className={
            'sui-flex sui-items-center sui-justify-center sui-absolute sui-bg-inverse '
            + 'sui-fill-inverse sui-h-6 sui-w-6 sui-z-10 sui-rounded-full sui--top-2 sui--right-2'
          }
        >
          <Close color="inherit" size="small" />
        </div>
        <SampleImage mini url={imageUrl} />
      </button>
    </div>
  );
};

TraySample.displayName = 'TraySample';

TraySample.propTypes = {
  imageUrl: string.isRequired,
  onClick: func,
};

TraySample.defaultProps = {
  onClick: null
};
