import {
  getSpecValue,
  getMetadataSampleColorSpecName,
  getSampleAttributeValues,
  getSampleColorSpecValue
} from './helpers';

const triggerInvalidDataNR = ({ erroredSchema, errorDescription }) => {
  window.LIFE_CYCLE_EVENT_BUS.trigger('invalid-data-scenario.found', {
    componentName: '@thd-olt-component-react/order-sample',
    erroredSchema,
    errorDescription
  });
};

export const isSampleValid = ({ anchorItemIds, attributes, sample, skuNames }) => {
  const sampleId = sample?.identifiers?.itemId;
  const isDiscontinued = !!sample?.availabilityType?.discontinued;
  const hasNoPrice = typeof sample?.pricing?.value === 'undefined' || sample.pricing.value === null;
  const isAnchorItemId = anchorItemIds.includes(sampleId);

  const isValid = !(hasNoPrice && !isDiscontinued) // must have a price if not discontinued
                && !isAnchorItemId; // sampleId must not match any anchorItemIds
  if (hasNoPrice && !isDiscontinued) {
    triggerInvalidDataNR({
      erroredSchema: 'product.pricing',
      errorDescription: `Sample with itemId ${sampleId} is missing a price even though it's not discontinued.`
    });
  } else if (isAnchorItemId) {
    triggerInvalidDataNR({
      erroredSchema: 'product.identifiers',
      // eslint-disable-next-line max-len
      errorDescription: `Sample with itemId ${sampleId} has the same itemId as an anchor product. This will cause the user to order the anchor product when they're meaning to order a sample`
    });
  } else {
    // These are looking for data errors, but they won't prevent a sample from being valid
    let errorDescription;
    const attributesSlice = attributes.slice(0, 2);
    const name = getSampleAttributeValues({
      itemId: sampleId,
      specificationGroup: sample.specificationGroup,
      attributes: attributesSlice
    });
    const specName = getMetadataSampleColorSpecName(attributes);
    if (name !== getSpecValue({ specificationGroup: sample.specificationGroup, specName })
      && name === getSpecValue({ specificationGroup: sample.specificationGroup, specName: 'Color Family' })) {
      // Sample is best represented to the customer when it has a sku name - not just the Color Family name
      // eslint-disable-next-line max-len
      errorDescription = `Sample with itemId ${sampleId} does not have a ${specName} attribute - this makes it hard for the customer to choose the right sample.`;
    } else {
      const attrNameArray = name.split(',');
      const attrNameInSkuNames = attrNameArray.filter((attrName) => skuNames.includes(attrName));
      const sampleSkuName = getSampleColorSpecValue(attributes, sample.specificationGroup);
      const notInSkuNames = !sampleSkuName
        || (!skuNames.includes(sampleSkuName) && !attrNameInSkuNames?.length > 0);
      if (notInSkuNames) {
        // Not _always_ an error, but the sample's sku name should usually be one of the options in super-sku
        // The only known exception so far is if the sample is a multi-pack
        // eslint-disable-next-line max-len
        errorDescription = `Sample with itemId ${sampleId} has a ${specName} attribute ${sampleSkuName} that doesn't match the anchor item's ${specName} attribute. If the sample is a multi-pack, then this is not an issue.`;
      }
    }
    if (errorDescription) {
      triggerInvalidDataNR({
        erroredSchema: 'product.specificationGroup',
        errorDescription
      });
    }
  }
  return isValid;
};

export const reportDuplicateSamples = ({ duplicateNames, orderSamples }) => {
  if (duplicateNames.length) {
    duplicateNames.forEach((name) => {
      const uniqueIds = [
        ...new Set(
          orderSamples.filter((sample) => sample.skuName === name)
            .map((sample) => sample.itemId)
        )
      ];
      if (uniqueIds.length > 1) {
        triggerInvalidDataNR({
          erroredSchema: 'product.identifiers',
          // eslint-disable-next-line max-len
          errorDescription: `Found duplicate samples - only one of these should be the correct one. itemIds: ${uniqueIds.join(', ')}`
        });
      }
    });
  }
};