import React from 'react';
import { Typography } from '@one-thd/sui-atomic-components';
import { number, oneOf } from 'prop-types';

export const SampleTotal = ({ textVariant, sampleTotal, subtotal }) => {
  return (
    <div className="sui-flex sui-flex-row sui-justify-between sui-px-1">
      <Typography variant={textVariant} weight="bold">
        Your Samples: {sampleTotal}
      </Typography>
      <Typography className="sui-text-right" variant={textVariant} weight="bold">
        Total:&nbsp; ${subtotal.toFixed(2)}
      </Typography>
    </div>
  );
};

SampleTotal.displayName = 'SampleTotal';

SampleTotal.propTypes = {
  textVariant: oneOf(['body-xs', 'body-base', 'body-lg']),
  sampleTotal: number,
  subtotal: number
};

SampleTotal.defaultProps = {
  textVariant: 'body-base',
  sampleTotal: 0,
  subtotal: 0
};
